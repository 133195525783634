import { useFormikContext } from 'formik';
import { OptionProp } from 'dss-ui-library';
import { FormFieldProps } from '../../../field';
import { FormikFields } from '../../FormikFields';
import { useFieldGroupContext } from '../../../fieldGroup';

interface OnStreetSelectionResponse {
  zipCode: string;
  city: string;
  street: string;
  district: string;
  mandant: string;
  hausLfdNr: string | number;
  areaCode: string;
}

interface StreetAutoSuggestProps extends FormFieldProps {
  placeholder?: string;
  targetCity: string;
  getOptions: (value: string) => Promise<OptionProp[]>;
  onSelection: (option: OptionProp) => OnStreetSelectionResponse;
}

export function StreetAutoSuggest({
  placeholder = 'Beispielstraße',
  field,
  e2e,
  targetCity,
  getOptions,
  onSelection,
}: StreetAutoSuggestProps) {
  const { name } = useFieldGroupContext();
  const fieldName = name + field.name;
  const { setFieldValue } = useFormikContext();

  const handleStreetSelection = ({
    option,
    targetCity,
  }: {
    option: OptionProp;
    targetStreet: string;
    targetCity: string;
  }) => {
    const { street, city } = onSelection(option);
    if (street && city) {
      setFieldValue(fieldName, street);
      setFieldValue(targetCity, city);
    }
  };
  return (
    <FormikFields.AutoSuggest
      label={field.label ?? 'Straße'}
      name={fieldName}
      e2e={e2e ?? 'street'}
      isRequired={field.mandatory}
      getOptions={getOptions}
      onChange={(option: OptionProp) =>
        handleStreetSelection({
          option,
          targetStreet: fieldName,
          targetCity,
        })
      }
      minLength={1}
      placeholder={field.placeholder ?? placeholder}
    />
  );
}
