import { useCallback, useState } from 'react';
import { OptionProp } from 'dss-ui-library';
import { useFormikContext } from 'formik';
import {
  fetchAddressOptions,
  fetchZIP,
  AddressSource,
  FetchZipResponse,
  FetchAddressType,
} from '../requests';
import { OnZipSelectionResponse } from '../fields/CustomFields';
import { FetchAddressReponse } from '@ncs-frontend-monorepo/availability';

export interface GetZipCodes {
  zipCode: string;
  mode?: AddressSource;
}

export interface HandleZipSelection {
  option: OptionProp;
  targetField: string;
  targetCity?: string;
}

export interface GetAddresses {
  type?: FetchAddressType;
  mode?: AddressSource;
  streetPartial: string;
  zipCode: string;
}

export interface HandleStreetSelection {
  option: OptionProp;
  targetField: string;
  targetZip?: string;
  targetCity?: string;
  targetAreaCode?: string;
}

export const useAddressAutoSuggest = () => {
  const { setFieldValue } = useFormikContext();
  const [zipCitySuggestion, setZipCitySuggestion] = useState<
    FetchZipResponse[]
  >([]);
  const [addressSuggestion, setAddressSuggestion] = useState<
    FetchAddressReponse[]
  >([]);

  const getZipCodes = async ({
    zipCode,
    mode = AddressSource.local,
  }: GetZipCodes): Promise<OptionProp[]> =>
    fetchZIP({ mode, zipCode })
      .then((addresses) => {
        setZipCitySuggestion(addresses);
        return addresses.map(({ zipCode }, ix) => ({
          value: ix.toString(),
          label: zipCode,
        }));
      })
      .catch(() => []);

  const onZipSelection = (option: OptionProp): OnZipSelectionResponse => {
    const selectedAddress = zipCitySuggestion
      ? zipCitySuggestion[parseInt(option.value, 10)]
      : null;
    setZipCitySuggestion([]);
    return {
      zipCode: selectedAddress?.zipCode || '',
      city: selectedAddress?.city || '',
    };
  };

  const handleZipSelection = useCallback(
    ({ option, targetField, targetCity = '' }: HandleZipSelection) => {
      const { zipCode, city } = onZipSelection(option);
      if (zipCode && city) {
        setFieldValue(targetField, zipCode);
        targetCity && setFieldValue(targetCity, city);
      }
    },
    [zipCitySuggestion],
  );

  const getAddresses = async ({
    type = FetchAddressType.streets,
    mode = AddressSource.local,
    streetPartial,
    zipCode,
  }: GetAddresses): Promise<OptionProp[]> =>
    zipCode.length < 5
      ? Promise.reject([])
      : fetchAddressOptions({
          type,
          mode,
          params: { zip: zipCode, street: streetPartial },
        })
          .then((addresses) => {
            setAddressSuggestion(addresses);
            return addresses
              .map(({ street }, ix) => ({
                value: ix.toString(),
                label: street,
              }))
              .filter(
                (item, index, self) =>
                  self.findIndex((t) => t.label === item.label) === index,
              );
          })
          .catch(() => []);

  const onStreetSelection = (option: OptionProp) => {
    const selectedAddress = addressSuggestion
      ? addressSuggestion[parseInt(option.value, 10)]
      : null;
    return {
      zipCode: selectedAddress?.zipCode || '',
      city: selectedAddress?.city || '',
      street: selectedAddress?.street || '',
      district: selectedAddress?.district || '',
      mandant: selectedAddress?.client || '',
      hausLfdNr: selectedAddress?.hausLfdnr || '',
      areaCode: selectedAddress?.areaCode || '',
    };
  };

  const handleStreetSelection = useCallback(
    ({
      option,
      targetField,
      targetZip = '',
      targetCity = '',
      targetAreaCode = '',
    }: HandleStreetSelection) => {
      const { street, zipCode, areaCode, city } = onStreetSelection(option);
      if (street) {
        setFieldValue(targetField, street);
      }
      if (targetZip && zipCode) {
        setFieldValue(targetZip, zipCode);
      }
      if (targetCity && city) {
        setFieldValue(targetCity, city);
      }
      if (targetAreaCode && areaCode) {
        setFieldValue(targetAreaCode, areaCode);
      }
    },
    [addressSuggestion],
  );

  return {
    getZipCodes,
    onZipSelection,
    handleZipSelection,
    getAddresses,
    onStreetSelection,
    handleStreetSelection,
  };
};
