import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { Textarea as NCTextArea, TextareaProps } from 'dss-ui-library';
import { Field, FieldProps } from 'formik';
import objectPath from 'object-path';

const CustomTextArea = ({
  field,
  form: { errors, touched, setFieldTouched },
  ...textAreaProps
}: FieldProps & TextareaProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, [field.name]);

  return (
    <NCTextArea
      {...field}
      {...textAreaProps}
      errorMessage={_touched && errorMessage ? errorMessage : undefined}
    />
  );
};

const Textarea: React.FC<TextareaProps> = ({ ...props }) => {
  const onBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props?.onBlur) props.onBlur(e as SyntheticEvent<any>);
    },
    [props.onBlur],
  );
  return <Field component={CustomTextArea} {...props} onBlur={onBlur} />;
};

export { Textarea };
