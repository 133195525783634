import { Grid, Separator, Spacer, Text, Tooltip } from 'dss-ui-library';
import HomeIdTooltip from './HomeIdTooltip';
import { HomeIdSelector } from './HomeIdSelector';
import { HomeIdType } from '../HomeId/Field';

interface HomeIdFormSectionProps {
  homeIdType: HomeIdType;
  label: string;
  name: string;
}

export const HomeIdFormSection: React.FC<HomeIdFormSectionProps> = ({
  label,
  name,
  homeIdType,
}) => {
  return (
    <>
      <Grid.Row>
        <Grid.Column xs={10}>
          <Text appearance="t2_2">Wie lautet deine Glasfaser-ID?</Text>
        </Grid.Column>
        <Grid.Column xs={2} hCenter>
          <Tooltip>
            <HomeIdTooltip homeIdType={homeIdType} />
          </Tooltip>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Spacer y={2} block />
          <Text appearance="t4">
            Mit der Angabe der Glasfaser-ID hilfst du uns, Verwechslungen zu
            vermeiden und beugst möglichen Verzögerungen bei der Installation
            vor.
          </Text>
        </Grid.Column>
        <Grid.Column>
          <Spacer t={3} />
          <HomeIdSelector homeIdType={homeIdType} name={name} label={label} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Spacer y={4} block>
            <Separator height={4} color="superlight-grey" />
          </Spacer>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
