import { FormikFields } from '../FormikFields';
import { FormFieldProps } from '../../field';
import { TextareaProps as TextareDefaultProps } from 'dss-ui-library';
import { useFieldGroupContext } from '../../fieldGroup';

type TextAreaProps = FormFieldProps & Pick<TextareDefaultProps, 'maxChars'>;

export function TextArea({ field, e2e, maxChars }: TextAreaProps): JSX.Element {
  const { name } = useFieldGroupContext();
  return (
    <FormikFields.Textarea
      name={name + field.name}
      label={field.label}
      placeholder={field.placeholder ?? ''}
      e2e={e2e ?? field.name}
      isRequired={field.mandatory}
      maxChars={maxChars}
    />
  );
}
