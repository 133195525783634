import { OptionProps } from 'dss-ui-library';

export enum BuildingTypes {
  apartment = 'apartment',
  single = 'single',
}

export const buildingTypes: OptionProps[] = [
  {
    value: BuildingTypes.apartment,
    label: 'Mehrfamilienhaus',
  },
  {
    value: BuildingTypes.single,
    label: 'Einfamilienhaus',
  },
];

export const buildingLevels: OptionProps[] = [
  {
    label: 'Souterrain',
    value: '-1',
  },
  {
    label: 'Erdgeschoss',
    value: '0',
  },
  ...Array.from({ length: 45 }, (_, x) => ({
    label: `${x + 1}. Stock`,
    value: `${x + 1}`,
  })),
];
