import { FormikFields } from '../FormikFields';
import { FormFieldProps } from '../../field';
import { useFieldGroupContext } from '../../fieldGroup';

export function Checkbox({ field, e2e }: FormFieldProps<boolean>): JSX.Element {
  const { name } = useFieldGroupContext();
  return (
    <FormikFields.Checkbox
      name={name + field.name}
      label={field.label}
      e2e={e2e ?? field.name}
      isRequired={field.mandatory}
    />
  );
}
