import { setStorage, getFromStorage } from '@ncs-frontend-monorepo/utils';

export type YupContext = {
  zipCode: string;
};

const YUP_CONTEXT_KEY = 'yup-context';

export function setYupContext(context: YupContext) {
  setStorage<YupContext>('sessionStorage', YUP_CONTEXT_KEY, context);
}

export function getYupContext() {
  return getFromStorage<YupContext>('sessionStorage', YUP_CONTEXT_KEY);
}
