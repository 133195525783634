import * as Yup from 'yup';

export const requiredText = 'Bitte fülle dieses Feld aus';
export const requiredDropDownText = 'Bitte auswählen';
export const requiredOptionText = 'Bitte wähle eine Option aus';
export const requiredLevel = 'Stockwerk ist ein Pflichtfeld';
export const requiredLocation = 'Lage ist ein Pflichtfeld';

export const maxFieldLengthText = (max: number) =>
  `Hier ist nur Platz für ${max} Zeichen`;
export const houseNumberRegEx =
  /^\d{1,4}((-\d{1,4})|([a-zA-Z]{1,16}-\d{0,4}))?[a-zA-Z]{0,16}$/;

export const internationalZipRegEx =
  /^(?=(?:[^ ]* ){0,2}[^ ]*$)(?=(?:[^-]*-)?[^-]*$)[a-zA-Z0-9\- ]{3,8}$/;

Yup.addMethod(Yup.string, 'stripWhitespace', function () {
  return this.transform((value: string) => value.replace(/\s*/g, ''));
});

export const addressValidation = {
  zipCode: Yup.string()
    .matches(/^[0-9]*$/, 'Eine PLZ darf nur aus Zahlen bestehen')
    .min(5, 'Eine PLZ muss aus 5 Zahlen bestehen')
    .max(5, 'Eine PLZ darf maximal aus 5 Zahlen bestehen')
    .required('PLZ ist ein Pflichtfeld'),
  // TODO create configurable callback function to toggle required method
  zipCodeOptional: Yup.string()
    .matches(/^[0-9]*$/, 'Eine PLZ darf nur aus Zahlen bestehen')
    .min(5, 'Eine PLZ muss aus 5 Zahlen bestehen')
    .max(5, 'Eine PLZ darf maximal aus 5 Zahlen bestehen'),
  zipCodeInternational: Yup.string()
    .min(3, 'Eine PLZ muss aus 3 bis 8 Zeichen bestehen')
    .max(8, 'Eine PLZ muss aus 3 bis 8 Zeichen bestehen')
    .required('PLZ ist ein Pflichtfeld')
    .matches(
      internationalZipRegEx,
      'Erlaubt sind nur Buchstaben, Ziffern sowie ein Bindestrich oder Leerzeichen',
    ),
  city: Yup.string()
    .required('Der Ort ist ein Pflichtfeld')
    .max(30, 'Hier ist nur Platz für 30 Zeichen'),
  street: Yup.string()
    .required('Die Straße ist ein Pflichtfeld')
    .max(200, 'Hier ist nur Platz für 200 Zeichen'),
  houseNumber: Yup.string()
    .required('Die Hausnummer ist ein Pflichtfeld')
    .max(15, 'Hier ist nur Platz für 15 Zeichen')
    .matches(
      houseNumberRegEx,
      'Bitte gib eine gültige Hausnummer an z. B. 15, 15a-17 oder 15a-z',
    ),
};

export const generalFieldValidation = {
  number: Yup.string().matches(
    /^[0-9]*$/,
    'Das Feld darf nur aus Zahlen bestehen',
  ),
  phone: Yup.string()
    // @ts-ignore
    .stripWhitespace()
    .matches(/^[\d\s]*$/, 'Die eingegebene Rufnummer ist ungültig')
    .max(10, 'Hier ist nur Platz für 10 Zeichen')
    .min(3, 'Eine Rufnummer muss aus mindestens 3 Zahlen bestehen'),
  phoneLong: Yup.string()
    // @ts-ignore
    .stripWhitespace()
    .matches(/^[\d\s]*$/, 'Die eingegebene Rufnummer ist ungültig')
    .max(15, 'Hier ist nur Platz für 15 Zeichen')
    .min(5, 'Eine Rufnummer muss aus mindestens 5 Zahlen bestehen'),
  date: Yup.string().length(10, 'Das Datum ist unvollständig'),
};

export const nameValidation = Yup.string()
  .min(2, 'Gib einen vollständigen Namen an')
  .max(30, maxFieldLengthText(30))
  .required(requiredText);

export const customerNoValidation = Yup.string()
  .matches(/^[0-9]+$/, 'Die Kundennummer ist ungültig')
  .min(7, 'Eine Kundennummer muss aus mindestens 7 Zahlen bestehen')
  .max(8, maxFieldLengthText(8));

export const ftthCustomerNoValidation = Yup.string().matches(
  /^1[0-9]{7}$/,
  'Die Kundennummer muss mit einer 1 beginnen und besteht aus 8 Ziffern',
);

export const emailValidation = Yup.string()
  .email(
    'Deine Eingabe ist ungültig. Die E-Mail-Adresse muss dem Format test@example.com entsprechen',
  )
  .matches(
    /^[^\u00df\u2013\u2014]+$/iu,
    'Deine E-Mail-Adresse beinhaltet ungültige Zeichen. Diese Zeichen sind nicht erlaubt: ß, —',
  );

export const urlValidation = Yup.string().matches(
  /^(www\.|https?:\/\/)+\S+\w/,
  'Deine Eingabe ist ungültig. Die URL muss dem Format www.example.de entsprechen',
);
