import * as Yup from 'yup';
import { contactFields } from '../../models';
import {
  addressValidation,
  emailValidation,
  generalFieldValidation,
  maxFieldLengthText,
  requiredText,
} from '../utils/fieldValidation';

export const contactSchema = {
  [contactFields.salutation.name]: Yup.string().required(requiredText),
  [contactFields.firstName.name]: Yup.string()
    .max(30, maxFieldLengthText(30))
    .required(requiredText),
  [contactFields.lastName.name]: Yup.string()
    .max(30, maxFieldLengthText(30))
    .required(requiredText),
  [contactFields.zipCode.name]:
    addressValidation.zipCode.required(requiredText),
  [contactFields.city.name]: addressValidation.city.required(requiredText),
  [contactFields.street.name]: addressValidation.street.required(requiredText),
  [contactFields.houseNumber.name]:
    addressValidation.houseNumber.required(requiredText),
  [contactFields.email.name]: emailValidation.required(requiredText),
  [contactFields.countryCode.name]: Yup.string().required(requiredText),
  [contactFields.phoneNumber.name]:
    generalFieldValidation.phoneLong.required(requiredText),
};
