import { Text } from 'dss-ui-library';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { TextLink } from '@ncs-frontend-monorepo/content-library';

const moreInformationFormal = 'Weitere Hinweise zum Datenschutz finden Sie';
const moreInformation = 'Weitere Hinweise zum Datenschutz findest du';

interface PrivacyInfoProps {
  url?: string;
  e2e?: string;
  isFormal?: boolean;
  title?: string;
}

export function PrivacyInfo({
  e2e,
  url = `https://www.${isNC() ? 'netcologne' : 'netaachen'}.de/datenschutz`,
  isFormal = true,
  title,
}: PrivacyInfoProps): React.ReactNode {
  return (
    <Text appearance="t5">
      Hinweis: Die Rechtmäßigkeit der Datenverarbeitung ergibt sich aus Art. 6
      Abs. 1 lit. b) DSGVO. {isFormal ? moreInformationFormal : moreInformation}{' '}
      <TextLink
        appearance="t5"
        e2e={e2e ?? 'privacy-policy'}
        noEventBubbling
        target="_blank"
        title={title ?? 'privacy-policy'}
        url={url}
      >
        hier
      </TextLink>
      .
    </Text>
  );
}
