import React, { useEffect, useState } from 'react';
import objectPath from 'object-path';
import {
  FileUpload as NCFileUpload,
  FileUploadProps,
  UploadedFileProps,
  ErrorMessage,
  Spacer,
} from 'dss-ui-library';
import { FieldArray, FieldArrayConfig, FieldArrayRenderProps } from 'formik';

function getUidsFromFileUpload(files: UploadedFileProps[]): string[] {
  return files.map((f) => f.uid);
}

const CustomFileUpload = ({
  name,
  push,
  remove,
  form: { errors, touched, setFieldTouched, values },
  ...fileUploadProps
}: FieldArrayRenderProps & FileUploadProps) => {
  const files = values[name];
  const errorMessage = objectPath.get(errors, name);
  const _touched = objectPath.get(touched, name);
  const [droppedFiles, setDroppedFiles] = useState<UploadedFileProps[]>(files);

  useEffect(() => {
    setFieldTouched(name, false);
  }, [name, setFieldTouched]);

  const handleFileUpload = (
    uploadedFile: UploadedFileProps,
    setProgress: (progress: number, error: string) => void,
  ) => {
    fileUploadProps.onFileUpload(uploadedFile, (progress, error) => {
      if (progress === 100 && !error) {
        if (!droppedFiles.find((_f) => uploadedFile.uid === _f.uid)) {
          const newFile = { ...uploadedFile, progress: 100, disabled: false };
          push(newFile);
          setDroppedFiles((currentDroppedFiles) => [
            ...currentDroppedFiles,
            newFile,
          ]);
        }
      }
      setProgress(progress, error ?? '');
    });
  };

  const handleFileDelete = (uploadedFile: UploadedFileProps): Promise<void> => {
    if (!uploadedFile.errorText) {
      return fileUploadProps
        .onFileDelete(uploadedFile)
        .then(() => {
          const index = droppedFiles.findIndex(
            ({ uid }) => uid === uploadedFile.uid,
          );

          if (index >= 0) {
            remove(index);
            setDroppedFiles(
              droppedFiles.filter((file) => file.uid !== uploadedFile.uid),
            );
          }
        })
        .catch((e) => console.error(e));
    }
    return new Promise((r) => r());
  };

  return (
    <>
      <NCFileUpload
        {...fileUploadProps}
        files={droppedFiles}
        subtitle={fileUploadProps.subtitle}
        onFileUpload={handleFileUpload}
        onFileDelete={handleFileDelete}
        name={name}
      />
      {_touched && errorMessage && (
        <>
          <Spacer t={1} block />
          <ErrorMessage e2e={`error-upload-${fileUploadProps.e2e}`}>
            {errorMessage}
          </ErrorMessage>
        </>
      )}
    </>
  );
};

const FileUpload: React.FC<FieldArrayConfig & FileUploadProps> = ({
  ...props
}) => (
  <FieldArray
    render={(helpers) => <CustomFileUpload {...props} {...helpers} />}
    {...props}
  />
);

export { FileUpload, getUidsFromFileUpload };
