import * as Yup from 'yup';
import { isValid } from 'iban';

import { requiredText } from '../../validation';
import { bankFields } from '../../models';

const contactPersonLengthText = 'Hier ist nur Platz für 60 Zeichen';
const ibanLengthText = 'Die IBAN darf nicht länger als 31 Zeichen sein';
const sepaText = 'Bitte erteile uns das SEPA-Lastschriftmandat';
const ibanFormatText =
  'Deine Eingabe ist nicht richtig, bitte überprüfe die IBAN';
const minIbanLength = 'Die IBAN muss aus mindestens 15 Zeichen bestehen';

Yup.addMethod(Yup.string, 'stripWhitespace', function () {
  return this.transform((value: string) => value.replace(/\s*/g, ''));
});

export const min15 = (_value: string) => {
  if (!_value) {
    return false;
  }
  const value = _value.replace(/\s/g, '');
  return value.length >= 15;
};

export const bankSchema = {
  [bankFields.accountOwner.name]: Yup.string().when(
    [bankFields.isAccountOwner.name],
    {
      is: 'yes',
      then: (schema) => schema.max(60, contactPersonLengthText),
      otherwise: (schema) =>
        schema.max(60, contactPersonLengthText).required(requiredText),
    },
  ),
  [bankFields.iban.name]: Yup.string()
    // @ts-ignore
    .stripWhitespace()
    .required(requiredText)
    .test('min15', minIbanLength, min15)
    .max(31, ibanLengthText)
    .test('is-iban-valid', ibanFormatText, (fieldValue) => {
      if (fieldValue && fieldValue.length < 15) return true;
      return isValid(fieldValue);
    }),
  [bankFields.sepaAgreement.name]: Yup.bool().when(
    [bankFields.isAccountOwner.name],
    {
      is: 'yes',
      then: (schema) => schema.oneOf([true], sepaText).required(sepaText),
    },
  ),
};
