import * as Yup from 'yup';

export type YupWhenCallback<TSchema extends Yup.Schema, TISchema> = {
  then: (schema: TSchema) => Yup.ISchema<TISchema>;
  otherwise?: (schema: TSchema) => Yup.ISchema<TISchema>;
};

type YupValidateStringConfig = {
  is: any;
  callback: YupWhenCallback<Yup.StringSchema, string>;
};

export function yupValidateString<TKey, TSchema extends Yup.Schema, TISchema>(
  key: string | string[],
  { is, callback }: YupValidateStringConfig,
) {
  return Yup.string().when(key, {
    is,
    then: callback.then,
    otherwise: callback.otherwise,
  });
}
