import { Expandable, Grid, Spacer } from 'dss-ui-library';
import { Checkbox } from '../../fields/CustomFields';
import {
  BillingContactDataModel,
  billingContactFields,
} from '../../models/billing';
import { BillingAddress } from '.';

interface DifferentBillingAddressProps {
  formValues: BillingContactDataModel;
}

export function DifferentBillingAddress({
  formValues,
}: DifferentBillingAddressProps): React.ReactNode {
  const isExpanded = formValues.hasDifferentBillingAddress;
  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Checkbox field={billingContactFields.hasDifferentBillingAddress} />
        </Grid.Column>
      </Grid.Row>
      <Spacer b={2} />
      <Expandable e2e="first" expanded={isExpanded}>
        <BillingAddress formValues={formValues} />
      </Expandable>
    </>
  );
}
