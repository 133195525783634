import { countryCodes } from '../../data';
import { FormFieldProps } from '../../field';
import { useFieldGroupContext } from '../../fieldGroup';
import { FormikFields } from '../FormikFields';

export function CountryCodeDropdown({
  field,
  e2e,
}: FormFieldProps): JSX.Element {
  const { name } = useFieldGroupContext();
  return (
    <FormikFields.Dropdown
      name={name + field.name}
      label={field.label}
      e2e={e2e ?? field.name}
      options={countryCodes}
      isRequired={field.mandatory}
    />
  );
}
