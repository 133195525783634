import { OptionProps } from 'dss-ui-library';

// Top providers which will be listed first
const topProviders: OptionProps[] = [
  { label: 'Telekom Deutschland GmbH', value: 'Telekom Deutschland GmbH' },
  { label: 'Vodafone GmbH', value: 'Vodafone GmbH' },
  {
    label: 'Vodafone NRW Unitymedia GmbH',
    value: 'Vodafone NRW Unitymedia GmbH',
  },
  { label: '1&1 Internet AG', value: '1&1 Internet AG' },
  { label: 'O2 Telefonica Germany', value: 'O2 Telefonica Germany' },
  { label: 'Deutsche Glasfaser GmbH', value: 'Deutsche Glasfaser GmbH' },
];

const providers: OptionProps[] = [
  { label: 'Maingau Energie GmbH', value: 'Maingau Energie GmbH' },
  { label: 'congstar GmbH', value: 'congstar GmbH' },
  { label: 'easybell GmbH', value: 'easybell GmbH' },
  { label: 'RSB Rhein Sieg', value: 'RSB Rhein Sieg' },
  {
    label: 'Versatel West GmbH / Telelev',
    value: 'Versatel West GmbH / Telelev',
  },
  { label: '010090 GmbH', value: '010090 GmbH' },
  { label: 'TELE2', value: 'TELE2' },
  { label: 'Schönenberg Computer GmbH', value: 'Schönenberg Computer GmbH' },
  { label: 'QSC AG', value: 'QSC AG' },
  { label: 'RelAix Networks GmbH', value: 'RelAix Networks GmbH' },
  { label: 'bn t Blatzheim Networks', value: 'bn t Blatzheim Networks' },
  { label: 'innogy TelNet GmbH', value: 'innogy TelNet GmbH' },
  {
    label: 'SOCO Networks Solutions GmbH',
    value: 'SOCO Networks Solutions GmbH',
  },
  { label: 'Verizon Deutschland GmbH', value: 'Verizon Deutschland GmbH' },
  { label: 'voxenergie GmbH', value: 'voxenergie GmbH' },
  { label: 'Ecotel Communication AG', value: 'Ecotel Communication AG' },
  { label: 'sipgate GmbH', value: 'sipgate GmbH' },
  { label: 'PrimaCom Berlin GmbH', value: 'PrimaCom Berlin GmbH' },
  { label: 'Ventelo', value: 'Ventelo' },
  { label: '3U Telecom GmbH', value: '3U Telecom GmbH' },
  {
    label: 'Communication Services (Tele2)',
    value: 'Communication Services (Tele2)',
  },
  { label: 'BT Germany GmbH & Co. OHG', value: 'BT Germany GmbH & Co. OHG' },
  { label: 'Primastrom', value: 'Primastrom' },
  { label: 'NFON AG', value: 'NFON AG' },
  { label: 'envia TEL GmbH', value: 'envia TEL GmbH' },
  { label: 'DNS:NET Internet', value: 'DNS:NET Internet' },
  {
    label: 'Deutsche Telefon Standard AG',
    value: 'Deutsche Telefon Standard AG',
  },
  { label: 'EWE TEL GmbH', value: 'EWE TEL GmbH' },
  { label: 'RWE Highspeed', value: 'RWE Highspeed' },
  { label: 'Drillisch Online GmbH', value: 'Drillisch Online GmbH' },
  {
    label: 'Greenfiber Internet & Dienste',
    value: 'Greenfiber Internet & Dienste',
  },
  {
    label: 'Vodafone Kabel Deutschl. GmbH',
    value: 'Vodafone Kabel Deutschl. GmbH',
  },
];

export const allProviders: OptionProps[] = [
  ...topProviders,
  ...providers.sort((a, b) => a.label.localeCompare(b.label)),
];
