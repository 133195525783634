import React, { useRef, useEffect, useState } from 'react';
import { TextField as NCTextField, TextFieldProps } from 'dss-ui-library';
import { Field, FieldProps } from 'formik';
import objectPath from 'object-path';
import { createTextMaskInputElement } from 'text-mask-core';
import { TextMask } from '../../types';

const CustomIban = ({
  field,
  form: { errors, touched, setFieldTouched },
  ...textFieldProps
}: FieldProps & TextFieldProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  const inputRef = useRef(null);
  const [textMask, setTextMask] = useState<TextMask | null>(null);

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      const textMaskInputElement = createTextMaskInputElement({
        inputElement: inputRef.current,
        mask: (value: string) => {
          const newMask = [...new Array(value.length)].map((_, index) => {
            if (index % 4 === 0 && index !== 0) {
              return [' ', /[\da-zA-Z]/];
            }
            return /[\da-zA-Z]/;
          });
          const flattedMask = newMask.flat();
          return flattedMask;
        },
        guide: false,
        keepCharPositions: true,
      });
      setTextMask(textMaskInputElement);
    }
  }, [inputRef.current]);

  return (
    <div data-cs-mask>
      <NCTextField
        {...field}
        {...textFieldProps}
        inputRef={inputRef}
        onChange={(e: any) => {
          textMask?.update();
          field.onChange({
            target: { name: field.name, value: e.target.value.toUpperCase() },
          });
        }}
        errorMessage={_touched && errorMessage ? errorMessage : undefined}
      />
    </div>
  );
};

const Iban: React.FC<TextFieldProps> = ({ ...props }) => (
  <Field component={CustomIban} {...props} />
);

export { Iban };
