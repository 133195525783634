import * as Yup from 'yup';
import { getYupContext } from '../../../../validation';
import { HomeIdType, HomeIds } from '../Field';

export const HOME_ID_LENGTH: {
  [key in HomeIdType]: { min: number; max: number };
} = {
  FTTH: {
    min: 14,
    max: 14,
  },
  FTTH_BSA_L2_DG: {
    min: 12,
    max: 18,
  },
  FTTH_BSA_L2: {
    min: 7,
    max: 7,
  },
} as const;

export const messages = {
  general:
    'Die eingegebene Glasfaser-ID ist falsch. Bitte korrigiere deine Eingabe oder lasse das Feld leer.',
  fixed: (min: number) => `Die Glasfaser-ID muss aus ${min} Zeichen bestehen.`,
  min: (min: number) =>
    `Die Glasfaser-ID muss aus mindestens ${min} Zeichen bestehen.`,
  max: (max: number) =>
    `Die Glasfaser-ID darf maximal aus ${max} Zeichen bestehen.`,
  specialCharacter: 'Die Glasfaser-ID darf nicht aus Sonderzeichen bestehen.',
  invalidZipCode:
    'Die ersten 5 Ziffern müssen identisch zu deiner Postleizahl sein.',
};

export const homeIdSchemaTelekom = (name: string) => ({
  [name]: Yup.string()
    .min(
      HOME_ID_LENGTH.FTTH_BSA_L2.min,
      messages.fixed(HOME_ID_LENGTH.FTTH_BSA_L2.min),
    )
    .max(
      HOME_ID_LENGTH.FTTH_BSA_L2.max,
      messages.max(HOME_ID_LENGTH.FTTH_BSA_L2.max),
    )
    .matches(/^[a-zA-Z0-9]*$/, messages.specialCharacter),
});

export const homeIdSchemaNC = (name: string) => ({
  [name]: Yup.string()
    .min(HOME_ID_LENGTH.FTTH.min, messages.fixed(HOME_ID_LENGTH.FTTH.min))
    .max(HOME_ID_LENGTH.FTTH.max, messages.max(HOME_ID_LENGTH.FTTH.max))
    .matches(/^[a-zA-Z0-9-]*$/, messages.specialCharacter),
});

export const homeIdSchemaDeutscheGlasfaser = (name: string) => ({
  [name]: Yup.string()
    .min(
      HOME_ID_LENGTH.FTTH_BSA_L2_DG.min,
      messages.min(HOME_ID_LENGTH.FTTH_BSA_L2_DG.min),
    )
    .max(
      HOME_ID_LENGTH.FTTH_BSA_L2_DG.max,
      messages.max(HOME_ID_LENGTH.FTTH_BSA_L2_DG.max),
    )
    .matches(/^[a-zA-Z0-9-]*$/, messages.specialCharacter)
    .test('wrong-zip', messages.invalidZipCode, (value) => {
      if (!value) return true;
      const context = getYupContext();
      const zipCode: string = context?.zipCode ?? '';
      if (zipCode === null) return true;
      return value?.startsWith(zipCode);
    }),
});

export function getHomeIdSchema(homeIdType: HomeIdType, name: string) {
  switch (homeIdType) {
    case HomeIds.FTTH_BSA_L2_DG:
      return homeIdSchemaDeutscheGlasfaser(name);

    case HomeIds.FTTH_BSA_L2:
      return homeIdSchemaTelekom(name);

    default:
      return homeIdSchemaNC(name);
  }
}
