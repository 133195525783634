import { FormikProps } from 'formik';
import objectPath from 'object-path';
import { RefObject, useEffect, useState } from 'react';
import {
  homeIdMaskDeutscheGlasfaser,
  homeIdMaskNC,
  homeIdMaskTelekom,
} from './textMasks';
import { TextMask } from '../../../../types';
import { HomeIdType, HomeIds } from '../Field';

type UseHomeIdFieldParams = {
  type: HomeIdType;
  form: FormikProps<any>;
  name: string;
  inputRef: RefObject<HTMLInputElement>;
};

export function useHomeIdField({
  type,
  form,
  name,
  inputRef,
}: UseHomeIdFieldParams) {
  const errorMessage = objectPath.get(form.errors, name);
  const touched = objectPath.get(form.touched, name);

  const [textMask, setTextMask] = useState<TextMask | null>(null);

  useEffect(() => {
    form.setFieldTouched(name, true);
  }, [name]);

  useEffect(() => {
    switch (type) {
      case HomeIds.FTTH:
        setTextMask(homeIdMaskNC(inputRef.current));
        break;

      case HomeIds.FTTH_BSA_L2:
        setTextMask(homeIdMaskTelekom(inputRef.current));
        break;

      case HomeIds.FTTH_BSA_L2_DG:
        setTextMask(homeIdMaskDeutscheGlasfaser(inputRef.current));
        break;

      default:
        setTextMask(homeIdMaskNC(inputRef.current));
    }
  }, [type]);

  return { textMask, touched, errorMessage };
}
