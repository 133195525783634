import * as Yup from 'yup';
import { YupWhenCallback, yupValidateString } from './validate';

export const yes = (
  names: string[],
  callback: YupWhenCallback<Yup.StringSchema, string>,
) => yupValidateString(names, { is: 'yes', callback });

export const no = (
  names: string[],
  callback: YupWhenCallback<Yup.StringSchema, string>,
) => yupValidateString(names, { is: 'no', callback });

export const selected = (
  names: string[],
  callback: YupWhenCallback<Yup.StringSchema, string>,
) => yupValidateString(names, { is: (val: string) => val !== '', callback });
