import { FormField } from '../field';

export type ContactDataModel = {
  salutation: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  zipCode: string;
  city: string;
  street: string;
  houseNumber: string;
  phoneNumber: string;
};

export type ContactData = {
  salutation: FormField;
  title: FormField;
  firstName: FormField;
  lastName: FormField;
  email: FormField;
  countryCode: FormField;
  zipCode: FormField;
  city: FormField;
  street: FormField;
  houseNumber: FormField;
  phoneNumber: FormField;
};

export const contactFields: ContactData = {
  salutation: {
    name: 'salutation',
    label: 'Anrede',
    mandatory: true,
  },
  title: {
    name: 'title',
    label: 'Titel',
  },
  firstName: {
    name: 'firstName',
    label: 'Vorname',
    mandatory: true,
  },
  lastName: {
    name: 'lastName',
    label: 'Nachname',
    mandatory: true,
  },
  zipCode: {
    name: 'zipCode',
    label: 'PLZ',
    mandatory: true,
    placeholder: 'z. B. 50126',
  },
  city: {
    name: 'city',
    label: 'Ort',
    mandatory: true,
  },
  street: {
    name: 'street',
    label: 'Straße',
    mandatory: true,
  },
  houseNumber: {
    name: 'houseNumber',
    label: 'Hausnummer',
    mandatory: true,
    placeholder: 'z. B. 17a-17c',
  },
  email: {
    name: 'email',
    label: 'E-Mail-Adresse',
    mandatory: true,
  },
  countryCode: {
    name: 'countryCode',
    label: 'Ländervorwahl',
    mandatory: true,
  },
  phoneNumber: {
    name: 'phoneNumber',
    label: 'Rufnummer bei Rückfragen',
    placeholder: 'z. B. 171 1234567',
    mandatory: true,
  },
} as const;

export const contactFieldsInitialData = {
  [contactFields.salutation.name]: '',
  [contactFields.title.name]: '',
  [contactFields.firstName.name]: '',
  [contactFields.lastName.name]: '',
  [contactFields.zipCode.name]: '',
  [contactFields.city.name]: '',
  [contactFields.street.name]: '',
  [contactFields.houseNumber.name]: '',
  [contactFields.email.name]: '',
  [contactFields.countryCode.name]: '49',
  [contactFields.phoneNumber.name]: '',
} as ContactDataModel;
