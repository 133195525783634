import { getEnv } from '@ncs-frontend-monorepo/utils';

import { Technology } from '@ncs-frontend-monorepo/availability';
import { fetcher } from '@ncs-frontend-monorepo/utils';

export const HOME_ID = 'homeId';

export const homeIdCheckURL = (): string =>
  `${getEnv().ORDER_SERVICE_URL}/check-home-id`;

type ValidateHomeIdParams = {
  homeId?: string;
  hausLfdnr: string;
  technology: Technology;
};

export async function validateHomeId({
  homeId,
  hausLfdnr,
  technology,
}: ValidateHomeIdParams) {
  if (!homeId) return true;

  const params = new URLSearchParams({
    [HOME_ID]: homeId,
    technology,
    hausLfdnr,
  });

  const isValid = await fetcher(homeIdCheckURL() + '?' + params);

  return isValid;
}
