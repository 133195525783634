import { Technology } from '@ncs-frontend-monorepo/availability';
export * from './schema';
export * from './HomeIdField';

export const HomeIds = {
  FTTH_BSA_L2_DG: Technology.FTTH_BSA_L2_DG,
  FTTH_BSA_L2: Technology.FTTH_BSA_L2,
  FTTH: Technology.FTTH,
} as const;

export type HomeIdType = (typeof HomeIds)[keyof typeof HomeIds];
