import { TextField as NCTextField, TextFieldProps } from 'dss-ui-library';
import { useRef } from 'react';
import { Field, FieldProps } from 'formik';
import { HOME_ID_LENGTH } from './schema';
import { useHomeIdField } from './useHomeIdField';
import { HomeIdType } from '..';

export type HomeIdTextFieldProps = TextFieldProps & { homeIdType: HomeIdType };
export type HomeIdFieldProps = FieldProps & HomeIdTextFieldProps;

function HomeIdField({
  field,
  form,
  homeIdType,
  ...textFieldProps
}: HomeIdFieldProps): React.ReactNode {
  const inputRef = useRef(null);

  const { errorMessage, textMask, touched } = useHomeIdField({
    form,
    inputRef,
    name: field.name,
    type: homeIdType,
  });

  return (
    <NCTextField
      {...field}
      {...textFieldProps}
      inputRef={inputRef}
      onChange={(e) => {
        const target = e.target as HTMLInputElement;
        if (target.value.length > HOME_ID_LENGTH[homeIdType].max) return;
        textMask?.update();
        field.onChange({
          target: { name: field.name, value: target.value.toUpperCase() },
        });
      }}
      errorMessage={touched && errorMessage ? errorMessage : undefined}
    />
  );
}

const HomeIdInput: React.FC<HomeIdTextFieldProps> = ({ ...props }) => (
  <Field component={HomeIdField} {...props} />
);

export default HomeIdInput;
