import { FormikFields } from '../FormikFields';
import { FormFieldProps } from '../../field';
import { Salutation } from '../../definitions';
import { useFieldGroupContext } from '../../fieldGroup';

export const salutationOptions = [
  { value: Salutation.HERR, label: 'Herr' },
  { value: Salutation.FRAU, label: 'Frau' },
];

export function SalutationDropdown({
  field,
  e2e,
}: FormFieldProps): JSX.Element {
  const { name } = useFieldGroupContext();
  return (
    <FormikFields.Dropdown
      name={name + field.name}
      label={field.label}
      placeholder={field.placeholder}
      e2e={e2e ?? field.name}
      isRequired={field.mandatory}
      options={salutationOptions}
    />
  );
}
