import { FormikFields } from '../FormikFields';
import { FormFieldProps } from '../../field';
import { useFieldGroupContext } from '../../fieldGroup';

export function TextField({ field, e2e }: FormFieldProps): JSX.Element {
  const { name } = useFieldGroupContext();
  return (
    <FormikFields.TextField
      name={name + field.name}
      label={field.label}
      placeholder={field.placeholder ?? ''}
      e2e={e2e ?? field.name}
      isRequired={field.mandatory}
    />
  );
}
