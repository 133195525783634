import * as Yup from 'yup';
import { billingContactFields } from '../../models';
import {
  addressValidation,
  emailValidation,
  maxFieldLengthText,
  requiredText,
} from '../utils/fieldValidation';

const requiredOnDifferentBillingAddress = (
  stringSchema: Yup.StringSchema = Yup.string(),
) => {
  return stringSchema.when(
    [billingContactFields.hasDifferentBillingAddress.name],
    {
      is: true,
      then: (schema) => schema.required(requiredText),
    },
  );
};

export const billingContactSchema = {
  [billingContactFields.hasDifferentBillingAddress.name]: Yup.bool(),
  [billingContactFields.email.name]: emailValidation,
  [billingContactFields.companyName.name]: requiredOnDifferentBillingAddress(),
  [billingContactFields.salutation.name]: Yup.string(),
  [billingContactFields.title.name]: Yup.string(),
  [billingContactFields.firstName.name]: Yup.string().max(
    30,
    maxFieldLengthText(30),
  ),
  [billingContactFields.lastName.name]: Yup.string().max(
    30,
    maxFieldLengthText(30),
  ),
  [billingContactFields.zipCode.name]: requiredOnDifferentBillingAddress(
    addressValidation.zipCodeOptional,
  ),
  [billingContactFields.city.name]: requiredOnDifferentBillingAddress(),
  [billingContactFields.street.name]: requiredOnDifferentBillingAddress(),
  [billingContactFields.houseNumber.name]: requiredOnDifferentBillingAddress(),
};
