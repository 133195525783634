import { useFormikContext } from 'formik';
import { Grid, Spacer, Text } from 'dss-ui-library';
import {
  SalutationDropdown,
  StreetAutoSuggest,
  TextField,
  TitleDropdown,
  ZipCodeAutoSuggest,
} from '../../fields/CustomFields';
import {
  billingAddressFields,
  BillingAddressDataModel,
} from '../../models/billing';
import { useAddressAutoSuggest } from '../../hooks/useAutoSuggest';
import { AddressSource, FetchAddressType } from '../../requests';

interface BillingAddressProps {
  formValues: BillingAddressDataModel;
}

export function BillingAddress({ formValues }: BillingAddressProps) {
  const { setFieldValue } = useFormikContext<{
    [name: string]: string;
  }>();

  const { getZipCodes, onZipSelection, getAddresses, onStreetSelection } =
    useAddressAutoSuggest();

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Text appearance="t4_2">
            Wohin sollen wir die Rechnungen schicken?*
          </Text>
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column marginBottom={3}>
          <TextField field={billingAddressFields.companyName} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column s={6} marginBottom={3}>
          <SalutationDropdown field={billingAddressFields.salutation} />
        </Grid.Column>
        <Grid.Column s={6} marginBottom={3}>
          <TitleDropdown field={billingAddressFields.title} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column s={6} marginBottom={3}>
          <TextField field={billingAddressFields.firstName} />
        </Grid.Column>
        <Grid.Column s={6} marginBottom={3}>
          <TextField field={billingAddressFields.lastName} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column s={4}>
          <ZipCodeAutoSuggest
            field={billingAddressFields.zipCode}
            getOptions={(zipCode: string) =>
              getZipCodes({ zipCode, mode: AddressSource.local })
            }
            onSelection={(options, parentName) => {
              const selection = onZipSelection(options);
              setFieldValue(
                parentName + billingAddressFields.city.name,
                selection.city,
              );
              return selection;
            }}
          />
        </Grid.Column>
        <Grid.Column s={8} marginBottom={3}>
          <TextField field={billingAddressFields.city} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column s={8}>
          <StreetAutoSuggest
            placeholder="Bitte geben Sie Ihre Rechnungsadresse an"
            e2e="billing-address-street"
            field={billingAddressFields.street}
            getOptions={(streetPartial) => {
              return getAddresses({
                streetPartial,
                zipCode: formValues.zipCode,
                type: FetchAddressType.streets,
                mode: AddressSource.local,
              });
            }}
            onSelection={onStreetSelection}
            targetCity={billingAddressFields.city.name}
          />
        </Grid.Column>
        <Grid.Column s={4} marginBottom={3}>
          <TextField field={billingAddressFields.houseNumber} />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}
