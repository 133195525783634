import { createTextMaskInputElement } from 'text-mask-core';
import { HOME_ID_LENGTH } from './schema';

type MaskElement = HTMLInputElement | null;

export const homeIdMaskNC = (element: MaskElement) =>
  createTextMaskInputElement({
    inputElement: element,
    mask: (value: string) => {
      const newMask = [];
      for (let i = 0; i < value.length; i++) {
        if (i % 4 === 0 && i !== 0 && i !== HOME_ID_LENGTH.FTTH.max - 2) {
          newMask.push('-');
        }
        newMask.push(/[\da-zA-Z]/);
      }
      return newMask;
    },
    guide: false,
    keepCharPositions: true,
  });

export const homeIdMaskTelekom = (element: MaskElement) =>
  createTextMaskInputElement({
    inputElement: element,
    mask: (value: string) => {
      const newMask = [];
      for (let i = 0; i < value.length; i++) {
        newMask.push(/[\da-zA-Z]/);
      }
      return newMask;
    },
    guide: false,
    keepCharPositions: true,
  });

export const homeIdMaskDeutscheGlasfaser = (element: MaskElement) =>
  createTextMaskInputElement({
    inputElement: element,
    mask: (value: string) => {
      const newMask = [];
      for (let i = 0; i < value.length; i++) {
        newMask.push(/[\da-zA-Z-]/);
      }
      return newMask;
    },
    guide: false,
    keepCharPositions: true,
  });
