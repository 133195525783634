import { useState, useEffect } from 'react';
import { fetcher, getEnv } from '@ncs-frontend-monorepo/utils';

export interface IbanResponse {
  bic: string;
  institution: string;
}

export enum IbanState {
  NotRequired = 'nicht erforderlich',
  IsDetermined = 'wird ermittelt',
}

export const useValidateIban = (_ibanValue: string, debounce = 500) => {
  const initialState = {
    bic: IbanState.IsDetermined,
    institution: IbanState.IsDetermined,
  };

  const ibanValue = _ibanValue.replace(/\s/g, '');
  const [bankDetails, setBankDetails] = useState<IbanResponse>(initialState);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (ibanValue.length >= 15) {
        if (!/^DE+/.test(ibanValue)) {
          return setBankDetails({
            bic: IbanState.NotRequired,
            institution: IbanState.NotRequired,
          });
        }
        try {
          const response = await fetcher(
            `${
              getEnv().ORDER_SERVICE_URL
            }/dss-core-uniserv-connector/bank-account-data/${ibanValue}`,
          );
          setBankDetails(
            response.valid
              ? {
                  bic: response.bic,
                  institution: response.bankname,
                }
              : initialState,
          );
        } catch (_) {
          setBankDetails(initialState);
        }
      } else {
        setBankDetails(initialState);
      }
    }, debounce);

    return () => clearTimeout(timeout);
  }, [ibanValue]);

  return { data: bankDetails };
};
