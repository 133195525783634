import React, { useEffect } from 'react';
import { Grid, Spacer, Text, OutputField } from 'dss-ui-library';
import { FormikFields } from '../../fields/FormikFields';
import { useFormikContext } from 'formik';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { bankFields } from '../../';
import { IbanState, useValidateIban } from '../../hooks';
import { RequiredInfo } from '../../utils';
import { Decision } from '../../definitions';

interface PaymentProps {
  iban: string;
  hasHeader?: boolean;
  hasRequiredInfo?: boolean;
}

export const Payment: React.FC<PaymentProps> = ({
  iban,
  hasHeader = true,
  hasRequiredInfo = true,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const isAccountOwner = values[bankFields.isAccountOwner.name];
  const { data } = useValidateIban(iban);
  const getOpacity = (value: string): number => {
    return value === IbanState.IsDetermined ? 0.3 : 1;
  };

  useEffect(() => {
    if (isAccountOwner === Decision.yes) {
      setFieldValue(bankFields.accountOwner.name, '', false);
    }

    if (isAccountOwner === Decision.no) {
      setFieldValue(bankFields.sepaAgreement.name, false, false);
    }

    if (
      data?.bic !== IbanState.IsDetermined &&
      data?.bic !== IbanState.NotRequired
    ) {
      setFieldValue(bankFields.bic.name, data.bic, false);
      setFieldValue(bankFields.institution.name, data.institution, false);
    } else {
      setFieldValue(bankFields.bic.name, '', false);
      setFieldValue(bankFields.institution.name, '', false);
    }
  }, [isAccountOwner, data]);

  return (
    <Grid fullWidth>
      {hasHeader && (
        <Grid.Row>
          <Grid.Column>
            <Text appearance="t2" color="blue">
              Deine Kontodaten
            </Text>
            <Spacer b={1} block />
            <Text appearance="t4">
              Noch eine letzte Frage zur Bankverbindung.
            </Text>
            <Spacer t={5} block />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>
          <Text appearance="t2_2">Bist du Kontoinhaber? *</Text>
          <Spacer t={2} block />
          <FormikFields.Radio
            name={bankFields.isAccountOwner.name}
            e2e="is-account-owner-yes"
            label="Ja"
            value={Decision.yes}
          />
          <Spacer t={2} block />
          <FormikFields.Radio
            name={bankFields.isAccountOwner.name}
            e2e="is-account-owner-no"
            label="Nein"
            value={Decision.no}
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      {isAccountOwner === Decision.no && (
        <Grid.Row>
          <Grid.Column>
            <FormikFields.TextField
              label={bankFields.accountOwner.label}
              name={bankFields.accountOwner.name}
              e2e="account-owner"
              isRequired
            />
            <Spacer b={3} block />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>
          <FormikFields.Iban
            label={bankFields.iban.label}
            name={bankFields.iban.name}
            e2e="iban"
            isRequired
          />
          <Spacer b={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={6}>
          <div data-cs-mask>
            <OutputField
              label={bankFields.bic.label}
              e2e="bic"
              textOpacity={getOpacity(data.bic)}
            >
              {data.bic}
            </OutputField>
          </div>
          <Spacer b={3} block />
        </Grid.Column>
        <Grid.Column s={6}>
          <div data-cs-mask>
            <OutputField
              label={bankFields.institution.label}
              e2e="credit-institution"
              textOpacity={getOpacity(data.institution)}
            >
              {data.institution}
            </OutputField>
          </div>
          <Spacer b={5} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Text appearance="t5">
            <FormikFields.Checkbox
              label={
                isNC()
                  ? 'Ich erteile NetCologne ein SEPA-Lastschriftmandat und autorisiere A) NetCologne Zahlungen von meinem Bankkonto mittels Lastschrift einzuziehen und weise B) mein Kreditinstitut an, die von NetCologne gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meiner Bank vereinbarten Bedingungen. *'
                  : 'Ich erteile NetAachen ein SEPA-Lastschriftmandat und autorisiere A) NetAachen Zahlungen von meinem Bankkonto mittels Lastschrift einzuziehen und weise B) mein Kreditinstitut an, die von NetAachen gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meiner Bank vereinbarten Bedingungen. *'
              }
              name={bankFields.sepaAgreement.name}
              disabled={isAccountOwner === Decision.no}
              e2e="sepa-agreement"
            />
          </Text>
          {isAccountOwner === Decision.no && (
            <>
              <Spacer t={4} block />
              <Text appearance="t4_2">
                Du hast einen abweichenden Kontoinhaber eingegeben. Um die
                Rechnungsbeträge wunschgemäß von dem genannten Konto abbuchen zu
                können, benötigen wir die Zustimmung und Unterschrift des
                Kontoinhabers. Das vorbereitete SEPA-Mandatsformular senden wir
                dir mit der Auftragsbestätigung zu.
              </Text>
            </>
          )}
        </Grid.Column>
      </Grid.Row>
      {hasRequiredInfo && (
        <Grid.Row>
          <Grid.Column>
            <RequiredInfo />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};
