import Image, { StaticImageData } from 'next/image';
import { Grid, Spacer, Text } from 'dss-ui-library';
import styles from './HomeIdTooltip.module.scss';
import HomeIdDeutscheGlasfaserImage from './assets/HomeIdDeutscheGlasfaser.jpg';
import HomeIdTelekomImage from './assets/HomeIdTelekom.jpg';
import HomeIdNcImage from './assets/HomeIdNC.jpg';
import { HomeIdType } from '..';
import { Technology } from '@ncs-frontend-monorepo/availability';

type Data = {
  text: string;
  image: StaticImageData | string;
};

const data: { [key in HomeIdType]: Data } = {
  [Technology.FTTH_BSA_L2_DG]: {
    text: 'Die ID findest du auf deiner Glasfaser-Dose. In Mehrfamilienhäusern befindet sich diese in der Regel direkt in deiner Wohnung, in Einfamilienhäusern oft im Keller. Die Glasfaser-ID besteht aus mindestens 12 Zeichen (inklusive Bindestriche) und beginnt mit deiner Postleitzahl, z. B. 48231EUH-2A-1.',
    image: HomeIdDeutscheGlasfaserImage,
  },
  [Technology.FTTH_BSA_L2]: {
    text: 'Die ID findest du auf deiner Glasfaser-Dose. In Mehrfamilienhäusern befindet sich diese in der Regel direkt in deiner Wohnung, in Einfamilienhäusern oft im Keller. Die Glasfaser-ID besteht aus 7 Zeichen, z. B. 123VBCP.',
    image: HomeIdTelekomImage,
  },
  [Technology.FTTH]: {
    text: 'Die ID findest du auf deiner Glasfaser-Dose. In Mehrfamilienhäusern befindet sich diese in der Regel direkt in deiner Wohnung, in Einfamilienhäusern oft im Keller. Die Glasfaser-ID besteht aus 12 Buchstaben, z. B. HAUS-AUTO-MANN.',
    image: HomeIdNcImage,
  },
};

interface HomeIdTooltipProps {
  homeIdType: HomeIdType;
}

export default function HomeIdTooltip({
  homeIdType,
}: HomeIdTooltipProps): React.ReactNode {
  return (
    <Spacer y={2}>
      <Grid>
        <Grid.Row>
          <Grid.Column marginBottom={2}>
            <Text appearance="t2">Angabe der Glasfaser-ID</Text>
          </Grid.Column>
          <Grid.Column marginBottom={3}>
            <Text appearance="t4">{data[homeIdType].text}</Text>
          </Grid.Column>
        </Grid.Row>
        <Image
          className={styles.homeIdImage}
          width={800}
          src={data[homeIdType].image}
          alt="Glasfaser-ID"
        />
      </Grid>
    </Spacer>
  );
}
