import { FormFieldProps } from '../../field';
import { FormikFields } from '../FormikFields';
import { titles } from '../../data';
import { useFieldGroupContext } from '../../fieldGroup';

export function TitleDropdown({ field, e2e }: FormFieldProps): JSX.Element {
  const { name } = useFieldGroupContext();
  return (
    <FormikFields.Dropdown
      name={name + field.name}
      label={field.label}
      e2e={e2e ?? field.name}
      options={titles}
    />
  );
}
