import { OrderFormField } from '@ncs-frontend-monorepo/order';

export type BankDataModel = {
  isAccountOwner: string;
  accountOwner: string;
  iban: string;
  sepaAgreement: false;
  bic: string;
  institution: string;
};

export type BankData = {
  isAccountOwner: OrderFormField;
  accountOwner: OrderFormField;
  iban: OrderFormField;
  sepaAgreement: OrderFormField;
  bic: OrderFormField;
  institution: OrderFormField;
};

export const bankFields: BankData = {
  isAccountOwner: {
    name: 'bank-isAccountOwner',
    label: '',
  },
  accountOwner: {
    name: 'bank-accountOwner',
    label: 'Abweichender Kontoinhaber',
  },
  iban: {
    name: 'bank-iban',
    label: 'IBAN',
  },
  sepaAgreement: {
    name: 'bank-sepaAgreement',
    label: '',
  },
  bic: {
    name: 'bank-bic',
    label: 'BIC',
  },
  institution: {
    name: 'bank-institution',
    label: 'Kreditinstitut',
  },
};

export const bankFieldsInitialData = {
  [bankFields.isAccountOwner.name]: 'yes',
  [bankFields.accountOwner.name]: '',
  [bankFields.iban.name]: '',
  [bankFields.sepaAgreement.name]: false,
  [bankFields.bic.name]: '',
  [bankFields.institution.name]: '',
} as BankDataModel;
