import { createContext, useContext } from 'react';

type FieldGroupContextType = {
  name: string;
};

const FieldGroupContext = createContext<FieldGroupContextType>({ name: '' });

export const useFieldGroupContext = () => useContext(FieldGroupContext);

interface FieldGroupProviderProps {
  children: React.ReactNode;
  name: string;
}
export function FieldGroupProvider({
  children,
  name,
}: FieldGroupProviderProps) {
  return (
    <FieldGroupContext.Provider value={{ name: name ? `${name}.` : '' }}>
      {children}
    </FieldGroupContext.Provider>
  );
}
