export enum SalutationFTTH {
  MISS = 'MISS',
  MISTER = 'MISTER',
}

export enum Salutation {
  FRAU = 'FRAU',
  HERR = 'HERR',
}

export enum Title {
  DR = 'DR',
  PROF = 'PROF',
  DRMED = 'DRMED',
  DIPLING = 'DIPLING',
  PROFDR = 'PROFDR',
  RA = 'RA',
  DIPLPSYCH = 'DIPLPSYCH',
  DIPLKFM = 'DIPLKFM',
}

export enum LegalForm {
  PRIVATE_CITIZEN = 'PRIVATE_CITIZEN',
  BUSINESS = 'BUSINESS',
  COMMUNITY_OF_OWNERS = 'COMMUNITY_OF_OWNERS',
  COMMUNITY_OF_HEIRS = 'COMMUNITY_OF_HEIRS',
}

export enum Decision {
  yes = 'yes',
  no = 'no',
}
