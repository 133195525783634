import { useField } from 'formik';
import HomeIdInput, { HomeIdTextFieldProps } from './Field/HomeIdField';
import { HomeIdType, HomeIds } from '../HomeId/Field';

interface HomeIdSelectorProps {
  homeIdType: HomeIdType;
  name: string;
  label: string;
}

export function HomeIdSelector(props: HomeIdSelectorProps): React.ReactNode {
  const [, { error }] = useField(props.name);

  const homeIdProps: HomeIdTextFieldProps = {
    homeIdType: props.homeIdType,
    name: props.name,
    label: props.label,
    errorMessage: error,
    e2e: 'home-id',
  };
  switch (props.homeIdType) {
    case HomeIds.FTTH_BSA_L2_DG:
      return <HomeIdInput {...homeIdProps} placeholder="z. B. 48321EUH-2A-1" />;

    case HomeIds.FTTH_BSA_L2:
      return <HomeIdInput {...homeIdProps} placeholder="z. B. 123VBCP" />;

    default:
      return (
        <HomeIdInput {...homeIdProps} placeholder="z. B. HAUS-AUTO-MANN" />
      );
  }
}
