import { useFormikContext } from 'formik';
import { OptionProp } from 'dss-ui-library';
import { FormikFields } from '../../FormikFields';
import { FormFieldProps } from '../../../field';
import { useFieldGroupContext } from '../../../fieldGroup';

export interface OnZipSelectionResponse {
  zipCode: string;
  city: string;
}

interface ZipCodeAutoSuggestProps extends FormFieldProps {
  getOptions: (value: string) => Promise<OptionProp[]>;
  onSelection: (option: OptionProp, name: string) => OnZipSelectionResponse;
}

export function ZipCodeAutoSuggest({
  field,
  e2e,
  getOptions,
  onSelection,
}: ZipCodeAutoSuggestProps): JSX.Element {
  const { name } = useFieldGroupContext();
  const { setFieldValue } = useFormikContext();

  const handleZipCodeSelection = ({ option }: { option: OptionProp }) => {
    const { zipCode } = onSelection(option, name);
    if (!zipCode) return;
    setFieldValue(name + field.name, zipCode);
  };

  return (
    <FormikFields.AutoSuggest
      label={field.label}
      name={name + field.name}
      isRequired={field.mandatory}
      e2e={e2e ?? 'zipCode'}
      getOptions={getOptions}
      onChange={(option: OptionProp) => handleZipCodeSelection({ option })}
      minLength={3}
      placeholder={field.placeholder ?? 'z. B. 50126'}
    />
  );
}
