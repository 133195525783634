import { FormField } from '../field';

export type BillingAddressDataModel = {
  salutation: string;
  title: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  city: string;
  street: string;
  houseNumber: string;
};

export type BillingAddressData = {
  companyName: FormField;
  salutation: FormField;
  title: FormField;
  firstName: FormField;
  lastName: FormField;
  zipCode: FormField;
  city: FormField;
  street: FormField;
  houseNumber: FormField;
};

export type BillingContactDataModel = BillingAddressDataModel & {
  hasDifferentBillingAddress: boolean;
  email: string;
};

export type BillingContactData = BillingAddressData & {
  hasDifferentBillingAddress: FormField<boolean>;
  email: FormField;
};

export const billingAddressFields: BillingAddressData = {
  companyName: {
    name: 'companyName',
    label: 'Firmenname',
    mandatory: true,
  },
  salutation: {
    name: 'salutation',
    label: 'Anrede',
  },
  title: {
    name: 'title',
    label: 'Titel',
  },
  firstName: {
    name: 'firstName',
    label: 'Vorname',
  },
  lastName: {
    name: 'lastName',
    label: 'Nachname',
  },
  zipCode: {
    name: 'zipCode',
    label: 'PLZ',
    mandatory: true,
    placeholder: 'z. B. 50126',
  },
  city: {
    name: 'city',
    label: 'Ort',
    mandatory: true,
  },
  street: {
    name: 'street',
    label: 'Straße',
    mandatory: true,
  },
  houseNumber: {
    name: 'houseNumber',
    label: 'Hausnummer',
    mandatory: true,
    placeholder: 'z. B. 17a-17c',
  },
} as const;

export const billingContactFields: BillingContactData = {
  hasDifferentBillingAddress: {
    name: 'hasDifferentBillingAddress',
    label: 'Abweichende Rechnungsadresse',
  },
  email: {
    name: 'email',
    label: 'E-Mail-Adresse für Rechnungsversand ',
  },
  ...billingAddressFields,
} as const;

export const billingAddressFieldsInitialData = {
  [billingAddressFields.companyName.name]: '',
  [billingAddressFields.salutation.name]: '',
  [billingAddressFields.title.name]: '',
  [billingAddressFields.firstName.name]: '',
  [billingAddressFields.lastName.name]: '',
  [billingAddressFields.zipCode.name]: '',
  [billingAddressFields.city.name]: '',
  [billingAddressFields.street.name]: '',
  [billingAddressFields.houseNumber.name]: '',
} as BillingAddressDataModel;

export const billingContactFieldsInitialData = {
  ...billingAddressFieldsInitialData,
  [billingContactFields.hasDifferentBillingAddress.name]: false,
  [billingContactFields.email.name]: '',
} as BillingContactDataModel;
