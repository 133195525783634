import { LegalForm, SalutationFTTH, Title } from '../../definitions';

export * from './providers';
export * from './countries';
export * from './buildings';

export const salutations = [
  { label: 'Frau', value: 'Frau' },
  { label: 'Herr', value: 'Herr' },
];

export const titles = [
  { label: 'Dr.', value: 'Dr.' },
  { label: 'Prof.', value: 'Prof.' },
  { label: 'Dr.med.', value: 'Dr.med.' },
  { label: 'Dipl.-Ing.', value: 'Dipl.-Ing.' },
  { label: 'Prof. Dr.', value: 'Prof. Dr.' },
  { label: 'Rechtsanwalt D.E.A.', value: 'Rechtsanwalt D.E.A.' },
  { label: 'Dipl.-Psych.', value: 'Dipl.-Psych.' },
  { label: 'Dipl.-Kfm.', value: 'Dipl.-Kfm.' },
];

export const FTTHSalutations = [
  { label: 'Frau', value: SalutationFTTH.MISS },
  { label: 'Herr', value: SalutationFTTH.MISTER },
];

export const FTTHTitles = [
  { label: 'Dr.', value: Title.DR },
  { label: 'Prof.', value: Title.PROF },
  { label: 'Dr.med.', value: Title.DRMED },
  { label: 'Dipl.-Ing.', value: Title.DIPLING },
  { label: 'Prof. Dr.', value: Title.PROFDR },
  { label: 'Rechtsanwalt D.E.A.', value: Title.RA },
  { label: 'Dipl.-Psych.', value: Title.DIPLPSYCH },
  { label: 'Dipl.-Kfm.', value: Title.DIPLKFM },
];

export const legalForms = [
  { label: 'Privatperson', value: LegalForm.PRIVATE_CITIZEN },
  { label: 'Firma', value: LegalForm.BUSINESS },
  { label: 'Eigentümergemeinschaft', value: LegalForm.COMMUNITY_OF_OWNERS },
  { label: 'Erbengemeinschaft', value: LegalForm.COMMUNITY_OF_HEIRS },
];

export const yesNoOptions = [
  { label: 'Ja', value: 'yes' },
  { label: 'Nein', value: 'no' },
];
