import {
  Mandant,
  correlationIdHeader,
  fetcher,
  getCorrelationId,
  getEnv,
} from '@ncs-frontend-monorepo/utils';

export const addressCheckURL = (): string =>
  `${getEnv().ORDER_SERVICE_URL}/dss-core-addresscheck/address-check`;

export enum AddressSource {
  local = 'LOCAL',
  national = 'NATIONAL',
  combined = 'NATIONAL_WITH_LOCAL',
}

export enum FetchAddressType {
  streets = 'streets',
  addresses = 'addresses',
}

interface FetchAddressOptions {
  type: FetchAddressType;
  mode?: AddressSource;
  params: {
    zip: string;
    street?: string;
    houseNumber?: string;
  };
}

//todo only use client (autosuggest) or mandant (availability)
export interface FetchAddressReponse {
  zipCode: string;
  city: string;
  street: string;
  houseNumber: string;
  district: string;
  mandant: Mandant;
  client?: string;
  hausLfdnr: number;
  areaCode: string;
  source: string;
}

interface FetchZIPParams {
  mode?: AddressSource;
  zipCode: string;
}

export interface FetchZipResponse {
  zipCode: string;
  city: string;
}
export async function fetchZIP({
  mode,
  zipCode,
}: FetchZIPParams): Promise<FetchZipResponse[]> {
  const searchParams = new URLSearchParams({ zipCode, ...(mode && { mode }) });
  return await fetcher(`${addressCheckURL()}/zipcodes?${searchParams}`, {
    headers: {
      [correlationIdHeader]: getCorrelationId(),
    },
  });
}

export async function fetchAddressOptions({
  type,
  mode = AddressSource.local,
  params,
}: FetchAddressOptions): Promise<FetchAddressReponse[]> {
  const searchParams = new URLSearchParams({ mode, ...params });
  return await fetcher(`${addressCheckURL()}/${type}?${searchParams}`, {
    headers: {
      [correlationIdHeader]: getCorrelationId(),
    },
  });
}
